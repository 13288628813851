import { useEffect, useMemo, useState } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { getDecodedJWT, getToken, setTokenCookie } from '../../auth/session';
import useRefreshToken from '../../hooks/useRefreshToken';
import ConfigHandler from './ConfigHandler';
import router from '../../router/router';
import SpinnerContainer from '../spinner/SpinnerContainer';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function ContentRouter() {
  const content = useRoutes(router);
  const query = useQuery();
  const location = useLocation();
  const [needRedirect, setNeedRedirect] = useState(true);
  let redirect;
  useEffect(() => {
    const token = getToken();
    const credentials = query.get('credentials');
    const params = new URLSearchParams(location.search);
    params.delete('credentials');

    const decodedJwt = getDecodedJWT(token || credentials);

    if (credentials) {
      setTokenCookie(credentials);
    }

    redirect =
      // eslint-disable-next-line react-hooks/exhaustive-deps
      (!token && !credentials) ||
      (decodedJwt &&
        decodedJwt?.exp &&
        Math.floor(Date.now() / 1000) > decodedJwt?.exp);

    if (redirect) {
      window.location = `${
        process.env.REACT_APP_API_URL
      }/auth/saml/login?originUrl=${
        location.pathname
      }?${params.toString()}` as any;
    } else {
      setNeedRedirect(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useRefreshToken();

  if (redirect || needRedirect) {
    return <SpinnerContainer />;
  }

  return <ConfigHandler>{content}</ConfigHandler>;
}
export default ContentRouter;
