import { getToken } from '../../auth/session';
import { Config } from '../../types/logic';
import api from '../api';
import { handleGetConfigError } from './configHelper';

export function getConfig(): Promise<Config> {
  const access_token = getToken();
  return api
    .get('/config', {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${access_token}`
      }
    })
    .then((response) => response.data)
    .catch(handleGetConfigError);
}
