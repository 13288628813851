import { takeLatest, put, call } from 'redux-saga/effects';
import { setTokenCookie } from '../../auth/session';
import * as authenticationService from '../../services/authentication/authenticationService';
/* import { setTokenCookie } from '../../auth/session'; */
import {
  refreshTokenFailed,
  refreshTokenSuccess,
  REFRESH_TOKEN_START,
  REFRESH_TOKEN_START_ACTION
} from '../actions/authentication/refreshTokenActions';

function* refreshToken(action: REFRESH_TOKEN_START_ACTION) {
  const { pathname } = action.payload;
  try {
    const response: { token: string } = yield call(
      authenticationService.refreshToken
    );

    setTokenCookie(response?.token);
    yield put(refreshTokenSuccess());
  } catch (e: unknown) {
    yield put(refreshTokenFailed((e as { message: string })?.message));
    window.location =
      `${process.env.REACT_APP_API_URL}/auth/saml/login?originUrl=${pathname}` as any;
  }
}

export default function* authenticationSaga() {
  yield takeLatest(REFRESH_TOKEN_START, refreshToken);
}
